<div class="app-main__inner">
    <div class="card-hover-shadow-2x mb-3 card" >
        <div class="card-header">            
            <div class="btn-actions-pane-right">
                <i class="fa fa-times" (click)="onClose()" style="cursor: pointer;"></i>
            </div>
        </div>
        <div class="card-body">
            <div class="row mb-2">
                <div class="col-6">
                    Nombre
                </div>
                <div class="col-6">
                    <input type="text" [(ngModel)]="perfil.nombre" class="form-control">
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-6">
                    Perfil Predeterminado
                </div>
                <div class="col-6">
                    <ui-switch [(ngModel)]="perfil.predeterminado"></ui-switch>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-6">
                    Cotizar motos
                </div>
                <div class="col-6">
                    <ui-switch [(ngModel)]="perfil.cotizamoto"></ui-switch>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-6">
                    Administrar Cotizaciones de Motos
                </div>
                <div class="col-6">
                    <ui-switch [(ngModel)]="perfil.cotizamotoadmin"></ui-switch>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-6">
                    Cotizar Repuestos
                </div>
                <div class="col-6">
                    <ui-switch [(ngModel)]="perfil.cotizarep"></ui-switch>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-6">
                    Administrar Cotizaciones de Repuestos
                </div>
                <div class="col-6">
                    <ui-switch [(ngModel)]="perfil.cotizarepadmin"></ui-switch>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-6">
                    Administrar Noticias
                </div>
                <div class="col-6">
                    <ui-switch [(ngModel)]="perfil.noticias"></ui-switch>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-6">
                    Administrar Paginas
                </div>
                <div class="col-6">
                    <ui-switch [(ngModel)]="perfil.paginas"></ui-switch>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-6">
                    Administrar Usadas
                </div>
                <div class="col-6">
                    <ui-switch [(ngModel)]="perfil.usadas"></ui-switch>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-6">
                    Administrar Sitios
                </div>
                <div class="col-6">
                    <ui-switch [(ngModel)]="perfil.sitios"></ui-switch>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-6">
                    Administrar Usuarios
                </div>
                <div class="col-6">
                    <ui-switch [(ngModel)]="perfil.adminuser"></ui-switch>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-6">
                    Orden
                </div>
                <div class="col-6">
                    <input [(ngModel)]="perfil.orden" type="number" min="0" step="1" class="form-control">
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-12">
                    <button class="mb-2 mr-2 btn-icon btn btn-primary btn-lg btn-block" (click)="onGuardar()">
                        <i class="fa fa-save btn-icon-wrapper"></i> Guardar
                    </button>
                </div>
            </div>
        </div>
        <div class="blockUI" style="display:none" *ngIf="loading"></div>
        <div class="blockUI blockOverlay" *ngIf="loading" style="display: none; border:none; margin: 0px; padding: 0px; width: 100%; height: 100%; top: 0px; left: 0px; position: absolute;"></div>
        <div class="blockUI undefined blockElement" style="position: absolute;" *ngIf="loading">
            <div class="loader mx-auto">
                <div class="ball-grid-pulse">
                    <div class="bg-white"></div>
                    <div class="bg-white"></div>
                    <div class="bg-white"></div>
                    <div class="bg-white"></div>
                    <div class="bg-white"></div>
                    <div class="bg-white"></div>
                    <div class="bg-white"></div>
                    <div class="bg-white"></div>
                    <div class="bg-white"></div>
                </div>
            </div>
        </div>
    </div>
</div>
