import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserI } from './../../interfaces/user';
import { LstorageService } from 'src/app/servicios/lstorage.service';
import { isNullOrUndefined } from 'util';
import { AuthService } from 'src/app/servicios/auth.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {
  user: UserI = {
    displayName : '',
    email: '',
    phoneNumber: '',
    photoURL: '',
    perfil: ''
  };
  username: string;
  loginuser = false;
  constructor(
    public auth: AuthService,
    private router: Router,
    private rutaActiva: ActivatedRoute
  ) {
    this.username = this.rutaActiva.snapshot.params.username;
    // tslint:disable-next-line: deprecation
    if (!isNullOrUndefined(this.username)) {
      this.loginuser = false;
    } else {
      this.auth.userData$.subscribe(user => {
        console.log(user);
        this.user.displayName = user.displayName;
        this.user.email = user.email;
        this.user.phoneNumber = user.phoneNumber;
        this.user.photoURL = user.photoURL;
      });
      this.loginuser = true;
    }
  }

  ngOnInit(): void {

  }

}
