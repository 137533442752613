<div class="app-main__inner">
    <div class="app-page-title">
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div>Perfiles de usuarios
                    <div class="page-title-subheading">
                        Aquí se configuran los perfiles de permisos de los usuarios
                    </div>
                </div>
            </div>
            <div class="page-title-actions">
                <a [routerLink]="['/administracion', 'perfiles', 'add']" type="button" class="btn-shadow mr-3 btn btn-dark">
                    <i class="fa fa-plus"></i> Agregar Perfil
                </a>
            </div>
        </div>
    </div>
    <div class="card-hover-shadow-2x mb-3 card" >
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>Perfil</th>
                                <th>Predeterminado</th>
                                <th>Opciones</th>
                            </tr>
                            <tr>
                                <th><input type="text" class="form-control"></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of perfiles">
                                <td>{{item.nombre}}</td>
                                <td>
                                    <span class="font-icon-wrapper" *ngIf="item.predeterminado">
                                        <i class="fa fa-star icon-gradient bg-warning"></i>
                                    </span>
                                    
                                </td>
                                <td>
                                    <a [routerLink]="['/administracion', 'perfiles', 'edit', item.id]" class="mb-2 mr-2 btn-hover-shine btn btn-primary btn-lg btn-block">
                                        <i class="fa fa-edit"></i> Editar
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="blockUI" style="display:none" *ngIf="loading"></div>
        <div class="blockUI blockOverlay" *ngIf="loading" style="display: none; border:none; margin: 0px; padding: 0px; width: 100%; height: 100%; top: 0px; left: 0px; position: absolute;"></div>
        <div class="blockUI undefined blockElement" style="position: absolute;" *ngIf="loading">
            <div class="loader mx-auto">
                <div class="ball-grid-pulse">
                    <div class="bg-white"></div>
                    <div class="bg-white"></div>
                    <div class="bg-white"></div>
                    <div class="bg-white"></div>
                    <div class="bg-white"></div>
                    <div class="bg-white"></div>
                    <div class="bg-white"></div>
                    <div class="bg-white"></div>
                    <div class="bg-white"></div>
                </div>
            </div>
        </div>
    </div>
    
    
</div>