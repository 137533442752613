import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { PerfilI } from '../interfaces/perfil';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {
  private perfilesCollection: AngularFirestoreCollection<PerfilI>;
  private perfiles: Observable<PerfilI[]>;
  constructor(
    private db: AngularFirestore
  ) {
    this.perfilesCollection = this.db.collection<PerfilI>('perfil');
  }

  getPerfiles() {
    this.perfiles = this.perfilesCollection.snapshotChanges().pipe(map(
      actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      }
    ));
    return this.perfiles;
  }

  getPerfilPred() {
    this.perfiles = this.db.collection<PerfilI>('perfil',
      ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('predeterminado', '==', true);
        return query;
      })
    .snapshotChanges().pipe(map(
      actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return {id, ...data };
        })
      }
    ))
    return this.perfiles;
  }

  getPerfil(id: string) {
    return this.perfilesCollection.doc<PerfilI>(id).valueChanges();
  }
  updatePerfil(id: string, user: PerfilI) {
    return this.perfilesCollection.doc(id).update(user);
  }
  addPerfil(user: PerfilI) {
    return this.perfilesCollection.add(user);
  }
  removePerfil(id: string) {
    return this.perfilesCollection.doc(id).delete();
  }
}
