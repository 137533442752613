<div class="app-main__inner">
    <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-4">
            <div class="card-shadow-primary card-border text-white mb-3 card bg-primary">
                <div class="dropdown-menu-header">
                    <div class="dropdown-menu-header-inner bg-primary">
                        <div class="menu-header-content">
                            <div class="avatar-icon-wrapper mb-3 avatar-icon-xl">
                                <div class="avatar-icon">
                                    <img
                                        [src]="user.photoURL"
                                        [alt]="user.displayName">
                                </div>
                            </div>
                            <div>
                                <h5 class="menu-header-title">{{user.displayName}}</h5>
                                <h6 class="menu-header-subtitle">{{user.perfil}}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center d-block card-footer">
                    <a [routerLink]="['/perfil', 'settings']" *ngIf="auth.userData$ | async" class="btn-shadow-dark btn-wider btn btn-dark">
                        <i class="pe-7s-config btn-icon-wrapper"></i>
                        Configuración
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>