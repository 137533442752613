
<div class="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar" [ngClass]="{'closed-sidebar': closesidebar, 'sidebar-mobile-open': opensidebarmobil}">
  <!-- navbar -->
  <div class="app-header header-shadow" *ngIf="auth.userData$ | async ">
    <div class="app-header__logo">
      <div class="logo-src"></div>
      <div class="header__pane ml-auto">
        <div>
          <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar" (click)="onCloseSidebar()" [ngClass]="{'is-active': closesidebar}">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="app-header__mobile-menu">
      <div>
        <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav" (click)="onOpenSidebarMobil()" [ngClass]="{'is-active': opensidebarmobil}">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="app-header__menu">
      <span>
        <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav" (click)="onToggleHeader()" [ngClass]="{'active': toggleheader}">
          <span class="btn-icon-wrapper">
            <i class="fa fa-ellipsis-v fa-w-6"></i>
          </span>
        </button>
      </span>
    </div>
    <div class="app-header__content" [ngClass]="{'header-mobile-open': toggleheader}">
      <div class="app-header-right">
        <div class="header-dots">
          <div class="dropdown">
            <button type="button" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown" class="p-0 mr-2 btn btn-link">
              <span class="icon-wrapper icon-wrapper-alt rounded-circle">
                <span class="icon-wrapper-bg bg-danger"></span>
                <i class="icon text-danger icon-anim-pulse ion-android-notifications"></i>
                <span class="badge badge-dot badge-dot-sm badge-danger">Notifications</span>
              </span>
            </button>
            <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu-xl rm-pointers dropdown-menu dropdown-menu-right">
              <div class="dropdown-menu-header mb-0">
                <div class="dropdown-menu-header-inner bg-deep-blue">
                  <div class="menu-header-image opacity-1" style="background-image: url('/assets/images/dropdown-header/city3.jpg');"></div>
                  <div class="menu-header-content text-dark">
                    <h5 class="menu-header-title">Notifications</h5>
                    <h6 class="menu-header-subtitle">You have <b>21</b> unread messages</h6>
                  </div>
                </div>
              </div>
              <ul class="tabs-animated-shadow tabs-animated nav nav-justified tabs-shadow-bordered p-3">
                <li class="nav-item">
                  <a role="tab" class="nav-link active" data-toggle="tab" href="#tab-messages-header">
                    <span>Messages</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a role="tab" class="nav-link" data-toggle="tab" href="#tab-events-header">
                    <span>Events</span>
                  </a>
                </li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane active" id="tab-messages-header" role="tabpanel">
                  <div class="scroll-area-sm">
                    <div class="scrollbar-container">
                      <div class="p-3">
                        <div class="notifications-box">
                          <div class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--one-column">
                            <div class="vertical-timeline-item dot-danger vertical-timeline-element">
                              <div><span class="vertical-timeline-element-icon bounce-in"></span>
                                <div class="vertical-timeline-element-content bounce-in">
                                  <h4 class="timeline-title">All Hands Meeting</h4>
                                  <span class="vertical-timeline-element-date"></span>
                                </div>
                              </div>
                            </div>
                            <div class="vertical-timeline-item dot-warning vertical-timeline-element">
                              <div><span class="vertical-timeline-element-icon bounce-in"></span>
                                <div class="vertical-timeline-element-content bounce-in">
                                  <p>Yet another one, at <span class="text-success">15:00 PM</span></p><span class="vertical-timeline-element-date"></span>
                                </div>
                              </div>
                            </div>
                            <div class="vertical-timeline-item dot-success vertical-timeline-element">
                              <div><span class="vertical-timeline-element-icon bounce-in"></span>
                                <div class="vertical-timeline-element-content bounce-in">
                                  <h4 class="timeline-title">Build the production release
                                    <span class="badge badge-danger ml-2">NEW</span>
                                  </h4>
                                  <span class="vertical-timeline-element-date"></span>
                                </div>
                              </div>
                            </div>
                            <div class="vertical-timeline-item dot-dark vertical-timeline-element">
                              <div>
                                <span class="vertical-timeline-element-icon bounce-in"></span>
                                <div class="vertical-timeline-element-content bounce-in">
                                  <h4 class="timeline-title">This dot has a dark state</h4>
                                  <span class="vertical-timeline-element-date"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="tab-events-header" role="tabpanel">
                  <div class="scroll-area-sm">
                    <div class="scrollbar-container">
                      <div class="p-3">
                        <div class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                          <div class="vertical-timeline-item vertical-timeline-element">
                            <div>
                              <span class="vertical-timeline-element-icon bounce-in"><i class="badge badge-dot badge-dot-xl badge-success"> </i></span>
                              <div class="vertical-timeline-element-content bounce-in">
                                <h4 class="timeline-title">All Hands Meeting</h4>
                                <p>Lorem ipsum dolor sic amet, today at <a href="javascript:void(0);">12:00 PM</a></p>
                                <span class="vertical-timeline-element-date"></span>
                              </div>
                            </div>
                          </div>
                          <div class="vertical-timeline-item vertical-timeline-element">
                            <div>
                              <span class="vertical-timeline-element-icon bounce-in">
                                <i class="badge badge-dot badge-dot-xl badge-warning"> </i>
                              </span>
                              <div class="vertical-timeline-element-content bounce-in">
                                <p>Another meeting today, at <b class="text-danger">12:00 PM</b></p>
                                <p>Yet another one, at <span class="text-success">15:00 PM</span></p>
                                <span class="vertical-timeline-element-date"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="nav flex-column">
                <li class="nav-item-divider nav-item"></li>
                <li class="nav-item-btn text-center nav-item">
                  <button class="btn-shadow btn-wide btn-pill btn btn-focus btn-sm">View Latest Changes</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="header-btn-lg pr-0">
          <div class="widget-content p-0">
            <div class="widget-content-wrapper" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="cursor: pointer;">
              <div class="widget-content-left">
                <div class="btn-group">
                  <img width="42" class="rounded-circle img" [src]="user.photoURL"  [alt]="user.displayName">
                  <div tabindex="-1" role="menu" aria-hidden="true" class="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-right">
                    <div class="dropdown-menu-header">
                      <div class="dropdown-menu-header-inner bg-info">
                        <div class="menu-header-image opacity-2" style="background-image: url('/assets/images/dropdown-header/city3.jpg');"></div>
                        <div class="menu-header-content text-left">
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-3">
                                <img width="42" class="rounded-circle img" [src]="user.photoURL"  [alt]="user.displayName">
                              </div>
                              <div class="widget-content-left">
                                <div class="widget-heading">{{user.displayName}}</div>
                                <div class="widget-subheading opacity-8">{{user.perfil}}</div>
                              </div>
                              <div class="widget-content-right mr-2">
                                <button class="btn-pill btn-shadow btn-shine btn btn-focus" (click)="onLogOut()">
                                  Logout
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="scroll-area-xs" style="height: 150px;">
                      <div class="scrollbar-container ps">
                        <ul class="nav flex-column">
                          <li class="nav-item-header nav-item">My Account</li>
                          <li class="nav-item">
                            <a [routerLink]="['/perfil']" class="nav-link">Perfil</a>
                          </li>
                          <li class="nav-item">
                            <a [routerLink]="['/perfil', 'settings']" class="nav-link">Settings</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <ul class="nav flex-column">
                      <li class="nav-item-divider mb-0 nav-item"></li>
                    </ul>
                    <div class="grid-menu grid-menu-2col">
                      <div class="no-gutters row">
                        <div class="col-sm-6">
                          <a routerLink="/perfil" class="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-warning">
                            <i class="pe-7s-user icon-gradient bg-amy-crisp btn-icon-wrapper mb-2"></i>
                            Perfil
                          </a>
                        </div>
                        <div class="col-sm-6">
                          <a routerLink="/tickets" class="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-danger">
                            <i class="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2"></i>
                            <b>Support Tickets</b>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget-content-left  ml-3 header-user-info">
                <div class="widget-heading">
                  {{user.displayName}}
                </div>
                <div class="widget-subheading">
                  {{user.perfil}}
                </div>
              </div>
              <div class="widget-content-left">
                <i class="fa fa-angle-down ml-2 opacity-8"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /navbar -->
  <!--<app-themesetting></app-themesetting>-->
  <div  [ngClass]="{'app-main': (fullpage | async)}">
    <!-- sidebar -->
    <div class="app-sidebar sidebar-shadow" *ngIf="auth.userData$ | async ">
      <div class="app-header__logo">
          <div class="logo-src"></div>
          <div class="header__pane ml-auto">
              <div>
                  <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                      <span class="hamburger-box">
                          <span class="hamburger-inner"></span>
                      </span>
                  </button>
              </div>
          </div>
      </div>
      <div class="app-header__mobile-menu">
          <div>
              <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
                  <span class="hamburger-box">
                      <span class="hamburger-inner"></span>
                  </span>
              </button>
          </div>
      </div>
      <div class="app-header__menu">
          <span>
              <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                  <span class="btn-icon-wrapper">
                      <i class="fa fa-ellipsis-v fa-w-6"></i>
                  </span>
              </button>
          </span>
      </div>
      <div class="scrollbar-sidebar ">
          <div class="app-sidebar__inner">
              <ul class="vertical-nav-menu">
                  <li [ngClass]="{'app-sidebar__heading': item.titulo, 'mm-active': item.showsubmenu}" *ngFor="let item of menu" routerLinkActive="mm-active">
                      <span *ngIf="item.titulo">{{item.title}}</span>
                      <a *ngIf="!item.titulo && item.submenu.length === 0" [routerLink]="item.url">
                          <i [ngClass]="item.icon"></i>{{item.title}}
                      </a>
                      <a *ngIf="!item.titulo && item.submenu.length > 0" style="cursor: pointer;" (click)="item.showsubmenu = !item.showsubmenu" >
                          <i [ngClass]="item.icon"></i>{{item.title}}
                          <i class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                      </a>
                      <ul [ngClass]="{'mm-collapse mm-show': item.showsubmenu, 'd-none': !item.showsubmenu}">
                          <li *ngFor="let sitem of item.submenu">
                              <a [routerLink]="sitem.url" routerLinkActive="mm-active">
                                  <i [ngClass]="sitem.icon"></i>{{sitem.title}}
                              </a>
                          </li>
                      </ul>
                  </li>
              </ul>
          </div>
      </div> 
      <!-- sidebar -->
    </div> 
    <div class="app-main__outer">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<div class="app-drawer-overlay d-none animated fadeIn"></div>