<div class="app-container">
    <div class="h-100 bg-premium-dark">
        <div class="d-flex h-100 justify-content-center align-items-center">
            <div class="mx-auto app-login-box col-md-8">
                <div class="app-logo-inverse mx-auto mb-3"></div>
                <div class="modal-dialog w-100">
                    <div class="modal-content">
                        <div class="modal-body">
                            <h5 class="modal-title">
                                <h4 class="mt-2">
                                    <div>Bienvenido,</div>
                                    <span>Solo toma <span class="text-success">unos segundos</span> crear su cuenta</span></h4>
                                </h5>
                                <div class="divider row"></div>
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <input name="email" id="exampleEmail" [(ngModel)]="email" (blur)="checkEmailAutorize()" placeholder="Correo electrónico aquí..." type="email" class="form-control" required>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <input name="text" id="exampleName" [(ngModel)]="nombre" placeholder="Nombre aquí..." type="text" class="form-control" required>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <input name="password" id="examplePassword" [(ngModel)]="pass1" placeholder="Contraseña aquí..." type="password" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <input name="passwordrep" id="examplePasswordRep" [(ngModel)]="pass2" placeholder="Repita la contraseña aquí..." type="password" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="divider row"></div>
                                <h6 class="mb-0">
                                    ¿Ya tienes una cuenta? <a [routerLink]="['/login']" class="text-primary">Ingresar</a> | <a [routerLink]="['/recoverpass']" class="text-primary">Recuperar contraseña</a>
                                </h6>
                            </div>
                            <div class="modal-footer d-block text-center">
                                <button class="btn-wide btn-pill btn-shadow btn-hover-shine btn btn-primary btn-lg" *ngIf="autorizado" (click)="registro()">
                                    Crear cuenta
                                </button>
                                <button class="btn-wide btn-pill btn-shadow btn-hover-shine btn btn-primary btn-lg" *ngIf="!autorizado" disabled>
                                    Crear cuenta
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>