import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PerfilI } from 'src/app/interfaces/perfil';
import { isNullOrUndefined } from 'util';
import { PerfilService } from 'src/app/servicios/perfil.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-editperfil',
  templateUrl: './editperfil.component.html',
  styleUrls: ['./editperfil.component.css']
})
export class EditperfilComponent implements OnInit {
  id: string = '';
  loading: boolean = true;
  perfil: PerfilI = {
    nombre: '',
    predeterminado: false,
    cotizamoto: false,
    cotizamotoadmin: false,
    cotizarep: false,
    cotizarepadmin: false,
    noticias: false,
    paginas: false,
    usadas: false,
    sitios: false,
    adminuser: false,
    orden: 0
  };
  constructor(
    private route: ActivatedRoute,
    private perfildb: PerfilService,
    private router: Router,
  ) {
    this.LoadEditPerfil();
  }

  ngOnInit(): void {
  }

  LoadEditPerfil() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (!isNullOrUndefined(this.id)) {
      this.perfildb.getPerfil(this.id).subscribe(res => {
        console.log(res);
        this.perfil = res;
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }

  onGuardar() {

    if (isNullOrUndefined(this.id)) {
      this.perfildb.addPerfil(this.perfil)
      .then(res => {
        Swal.fire({
          icon: 'success',
          title: 'Perfil agregado',
          // tslint:disable-next-line: max-line-length
          html: 'El perfil fue agregado exitosamente'
        }).then(() => {
          this.router.navigate(['/administracion', 'perfiles']);
        });
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          // tslint:disable-next-line: max-line-length
          html: 'Error al intentar crear el perfil, por favor inténtelo nuevamente'
        }).then(() => {
          this.router.navigate(['/administracion', 'perfiles']);
        });
      });
    } else {
      this.perfildb.updatePerfil(this.id, this.perfil)
      .then(res => {
        Swal.fire({
          icon: 'success',
          title: 'Perfil actualizado',
          // tslint:disable-next-line: max-line-length
          html: 'El perfil fue actualizado exitosamente'
        }).then(() => {
          this.router.navigate(['/administracion', 'perfiles']);
        });
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          // tslint:disable-next-line: max-line-length
          html: 'Error al intentar actualizar el perfil, por favor inténtelo nuevamente'
        }).then(() => {
          this.router.navigate(['/administracion', 'perfiles']);
        });
      });
    }
  }

  onClose() {
    this.router.navigate(['/administracion', 'perfiles']);
  }

}
