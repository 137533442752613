import { Component, OnInit } from '@angular/core';

import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/servicios/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  email = '';
  password = '';
  constructor(
    private router: Router,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
  }

  onLogin() {
    this.auth.loginByEmail(this.email, this.password)
    .then(res => {
      this.router.navigate(['/dashboard']);
    })
    .catch(err => {
      let msg = '';
      switch (err.code) {
        case 'auth/user-disabled':
            msg = 'Su usuario se encuentra deshabilitado por lo que no puede ser utilizado para ingresar';
            break;
        case 'auth/invalid-email':
          msg = 'El email ingresado no tiene el formato correcto como email';
          break;
        case 'auth/user-not-found':
          msg = 'La cuenta con la que intenta ingresar no existe, por favor regístrese para poder continuar';
          break;
        case 'auth/wrong-password':
          msg = 'La contraseña ingresada es errónea, por favor verifique su información';
          break;
      }
      Swal.fire({
        icon: 'error',
        title: 'Error',
        html: msg
      });
    });
  }
}
