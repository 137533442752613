import { Component, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'util';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { AuthService } from 'src/app/servicios/auth.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserI } from './interfaces/user';
import { PerfilService } from './servicios/perfil.service';
import { Router } from '@angular/router';
import { PerfilI } from './interfaces/perfil';
import { MenuI } from './interfaces/menu';
import { MessagingService } from './servicios/messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'RS';
  message;
  fullpage: Observable<boolean>;
  user: UserI = {
    displayName : '',
    email: '',
    phoneNumber: '',
    photoURL: '',
    perfil: ''
  };
  menu: any[] = [];
  perfil: PerfilI;
  closesidebar: boolean = false;
  opensidebarmobil: boolean = false;
  toggleheader: boolean = false;
  constructor(
    public auth: AuthService,
    public dbperfil: PerfilService,
    private router: Router,
    public perfildb: PerfilService,
    private msgService: MessagingService
  ) {
    this.checkUser();
  }

  ngOnInit() {
    this.msgService.getPermission();
    this.msgService.receiveMessage();
    this.message = this.msgService.currentMessage;
    console.log(this.message);
  }

  checkUser() {
    this.fullpage = this.auth.userData$.pipe(
      map(user => {
        if (!user) {
          return false;
        } else {
          return true;
        }
      })
    );
    this.auth.userData$.subscribe(user => {
      this.user.displayName = user.displayName;
      this.user.photoURL = user.photoURL;
      this.auth.getUser(user.uid).subscribe(u => {
        let us: UserI;
        // tslint:disable-next-line: deprecation
        if (!isNullOrUndefined(u)) {
          us = u;
          us = {
            displayName: user.displayName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            photoURL: user.photoURL,
            creationTime: (new Date(user.metadata.creationTime).getTime() / 1000),
            lastSignInTime: (new Date(user.metadata.lastSignInTime).getTime() / 1000)
          };
          this.auth.updateUser(user.uid, us);
        } else {
          let defperfil: string;
          us = {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            photoURL: user.photoURL,
            creationTime: (new Date(user.metadata.creationTime).getTime() / 1000),
            lastSignInTime: (new Date(user.metadata.lastSignInTime).getTime() / 1000)
          };
          this.dbperfil.getPerfilPred().subscribe(res => {
            console.log(res);
            us.perfil = res[0].id;
            this.auth.setUser(user.uid, us);
          });
        }
        this.perfildb.getPerfil(u.perfil).subscribe(p => {
          this.menu = [];
          this.perfil = p;
          console.log(this.perfil);
          this.addMenuData({
            title: 'Menu',
            url: ['#'],
            titulo: true,
            submenu: []
          });
          this.addMenuData({
            title: 'Dashboards',
            icon: 'metismenu-icon pe-7s-rocket',
            url: ['/dashboard'],
            titulo: false,
            submenu: []
          });
          this.addMenuData({
            title: 'Contactos',
            icon: 'metismenu-icon pe-7s-users',
            url: ['/contactos'],
            titulo: false,
            submenu: []
          });
          this.addMenuData({
            title: 'Ayuda',
            icon: 'metismenu-icon pe-7s-help1',
            url: ['/ayuda'],
            titulo: false,
            submenu: []
          });

          if (this.perfil.cotizamoto || this.perfil.cotizamotoadmin) {
            this.addMenuData({
              title: 'Cotizaciones Motos',
              url: ['#'],
              titulo: true,
              submenu: []
            });
            this.addMenuData({
              title: 'Cotizaciones Manuales',
              icon: 'metismenu-icon pe-7s-call',
              url: ['/cotizaciones', 'manual'],
              titulo: false,
              submenu: []
            });
            this.addMenuData({
              title: 'Calendario',
              icon: 'metismenu-icon pe-7s-date',
              url: ['/cotizaciones', 'calendario'],
              titulo: false,
              submenu: []
            });
            this.addMenuData({
              title: 'Listado de Cotizaciones',
              icon: 'metismenu-icon pe-7s-menu',
              url: ['/cotizaciones', 'listado'],
              titulo: false,
              submenu: []
            });
            this.addMenuData({
              title: 'Mis Cotizaciones Respondidas',
              icon: 'metismenu-icon lnr-user',
              url: ['/cotizaciones', 'mis_cotizaciones'],
              titulo: false,
              submenu: []
            });

            if (this.perfil.cotizamotoadmin) {
              const coti_submenu: any[] = [];

              let coti_admin_user: MenuI = {
                title: 'Usuarios',
                icon: 'metismenu-icon',
                url: ['/cotizaciones', 'administrador', 'usuarios'],
                titulo: false
              };
              coti_submenu.push(coti_admin_user);
              let coti_admin_estadisticas: MenuI = {
                title: 'Estadisticas',
                icon: 'metismenu-icon',
                url: ['/cotizaciones', 'administrador', 'estadisticas'],
                titulo: false
              };
              coti_submenu.push(coti_admin_estadisticas);
              let coti_admin_bajacoti: MenuI = {
                title: 'Dar de Baja Cotizacion',
                icon: 'metismenu-icon',
                url: ['/cotizaciones', 'administrador', 'bajacoti'],
                titulo: false
              };
              coti_submenu.push(coti_admin_bajacoti);

              this.addMenuData({
                title: 'Administracion Cotizaciones',
                icon: 'metismenu-icon  pe-7s-edit',
                titulo: false,
                submenu: coti_submenu,
                showsubmenu: false
              });
            }
          }
          if (this.perfil.adminuser) {
            this.addMenuData({
              title: 'Administracion',
              url: ['#'],
              titulo: true,
              submenu: []
            });
            this.addMenuData({
              title: 'Listado Usuarios',
              icon: 'metismenu-icon pe-7s-users',
              url: ['/administracion', 'users'],
              titulo: false,
              submenu: []
            });
            this.addMenuData({
              title: 'Perfiles de Usuarios',
              icon: 'metismenu-icon pe-7s-unlock',
              url: ['/administracion', 'perfiles'],
              titulo: false,
              submenu: []
            });
          }
        });
      });
    });
  }

  onLogOut() {
    this.auth.logout().then(res => {
      this.router.navigate(['/login']);
    });
  }
  addMenuData(menu: MenuI) {
    this.menu.push(menu);
  }
  onCloseSidebar() {
    this.closesidebar = !this.closesidebar;
  }
  onOpenSidebarMobil() {
    this.opensidebarmobil = !this.opensidebarmobil;
  }
  onToggleHeader() {
    this.toggleheader = !this.toggleheader;
  }
}
