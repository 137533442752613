import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recoverpass',
  templateUrl: './recoverpass.component.html',
  styleUrls: ['./recoverpass.component.css']
})
export class RecoverpassComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
