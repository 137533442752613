import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/servicios/auth.service';
import { UserI } from 'src/app/interfaces/user';
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {

  email:string = '';
  nombre: string = '';
  pass1: string = '';
  pass2: string = '';
  // tslint:disable-next-line: max-line-length
  downloadURL = 'https://firebasestorage.googleapis.com/v0/b/rs-shop-7d04f.appspot.com/o/default-user-image.png?alt=media&token=9e08be4a-9943-4b41-8f33-15ab8ce9b834';
  autorizado: boolean = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private storage: AngularFireStorage
  ) {
    
  }

  ngOnInit(): void {
  }


  checkEmailAutorize() {
    let autorizedomine: string[] = [
      'rsltda.cl', 'ktm.cl', 'ktm-bikes.cl', 'polarischile.cl', 'rs-chile.cl', 'ktm.com'
    ];
    if (this.email !== '') {
      let domine = this.email.split('@');
      let i = 0;
      autorizedomine.forEach((v, k) => {
        if (domine[1] === v) {
          this.autorizado = true;
          i = 1;
        }
      });
      if (i === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Dominio no autorizado',
          // tslint:disable-next-line: max-line-length
          html: 'El correo que esta utilizando no esta autorizado para registrarse manualmente, si aun requiere registrarse por favor utilice un correo autorizado o solicite a informática la creación manual de su usuario.'
        });
      }
    } else {
      this.autorizado = false;
    }
  }

  registro() {
    if (this.pass1 !== '' && this.pass2 !== '' && this.pass1 === this.pass2) {
      this.auth.CreateUserByEmail(this.email, this.pass1)
      .then(res => {
        this.auth.updateProfile(this.nombre, this.downloadURL)
        .then(xres => {
          this.router.navigate(['/dashboard']);
        }).catch(xerr => {
          console.log(xerr);
          this.router.navigate(['/dashboard']);
        });
      }).catch(err => {
        let msg = '';
        switch (err.code) {
          case 'auth/email-already-in-use':
              // tslint:disable-next-line: max-line-length
              msg = 'El email con el que se intenta registrar ya se encuentra registrado, si no recuerda su contraseña utilice la opción de recuperar contraseña';
              break;
          case 'auth/invalid-email':
            msg = 'El email ingresado no tiene el formato correcto como email';
            break;
          case 'auth/operation-not-allowed':
            msg = 'No se pudo registrar la cuenta debido a un error interno, por favor informe de inmediato a informática.';
            break;
          case 'auth/weak-password':
            msg = 'Su contraseña no es lo suficientemente segura para ser registrada de esta forma';
            break;
        }
        Swal.fire({
          icon: 'error',
          title: 'Error',
          // tslint:disable-next-line: max-line-length
          html: msg
        });
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Las contraseñas no coinciden',
        // tslint:disable-next-line: max-line-length
        html: 'Las contraseñas ingresadas no coinciden, por favor intente nuevamente'
      });
    }
  }
}
