import { Component, OnInit } from '@angular/core';
import { PerfilI } from 'src/app/interfaces/perfil';
import { PerfilService } from 'src/app/servicios/perfil.service';

@Component({
  selector: 'app-perfiles',
  templateUrl: './perfiles.component.html',
  styleUrls: ['./perfiles.component.css']
})
export class PerfilesComponent implements OnInit {
  perfiles: PerfilI[] = [];
  loading: boolean = true;
  constructor(
    private perfildb: PerfilService
  ) { }

  ngOnInit(): void {
    this.LoadPerfiles();
  }

  LoadPerfiles() {
    this.perfildb.getPerfiles().subscribe(res => {
      this.perfiles = res.sort((a, b) => a.orden - b.orden);
      this.loading = false;
    });
  }

}
