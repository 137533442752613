import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { NotloginGuard } from './guards/notlogin.guard';

//no autenticate user
import { LoginComponent } from './pages/login/login.component';
import { RegistroComponent } from './pages/registro/registro.component';
import { RecoverpassComponent } from './pages/recoverpass/recoverpass.component';
//user autenticate
//comunes
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { SettingsComponent } from './pages/perfil/settings/settings.component';
//administracion
import { ListadousersComponent } from './pages/administracion/listadousers/listadousers.component';
import { PerfilesComponent } from './pages/administracion/perfiles/perfiles.component';
import { EditperfilComponent } from './pages/administracion/perfiles/editperfil/editperfil.component';



const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'perfil/settings',
    component: SettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'perfil/:username',
    component: PerfilComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'perfil',
    component: PerfilComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'administracion/perfiles/edit/:id',
    component: EditperfilComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'administracion/perfiles/add',
    component: EditperfilComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'administracion/perfiles',
    component: PerfilesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'administracion/users',
    component: ListadousersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'administracion',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NotloginGuard]
  },
  {
    path: 'registro',
    component: RegistroComponent,
    canActivate: [NotloginGuard]
  },
  {
    path: 'recoverpass',
    component: RecoverpassComponent,
    canActivate: [NotloginGuard]
  },
  { path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  }
];
// { path: '**', component: PageNotFoundComponent }

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
