import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { ImagePreloadDirective } from './directivas/image-preload.directive';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { SettingsComponent } from './pages/perfil/settings/settings.component';

import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { RegistroComponent } from './pages/registro/registro.component';
import { RecoverpassComponent } from './pages/recoverpass/recoverpass.component';
import { PerfilesComponent } from './pages/administracion/perfiles/perfiles.component';
import { ListadousersComponent } from './pages/administracion/listadousers/listadousers.component';
import { EditperfilComponent } from './pages/administracion/perfiles/editperfil/editperfil.component';
import { UiSwitchModule } from 'ngx-toggle-switch';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    ImagePreloadDirective,
    PerfilComponent,
    SettingsComponent,
    RegistroComponent,
    RecoverpassComponent,
    PerfilesComponent,
    ListadousersComponent,
    EditperfilComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    ReactiveFormsModule,
    UiSwitchModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
