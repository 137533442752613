import { Injectable } from '@angular/core';
import { UserI } from 'src/app/interfaces/user';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userData$: Observable<firebase.User>;
  private usuariosCollection: AngularFirestoreCollection<UserI>;
  private usuarios: Observable<UserI[]>;
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore
  ) {
    this.userData$ = afAuth.authState;
    this.usuariosCollection = this.afs.collection<UserI>('usuarios');
  }

  CreateUserByEmail(email: string, pass: string) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, pass);
  }

  loginByEmail(email: string, pass: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, pass);
  }

  logout() {
    return this.afAuth.auth.signOut();
  }

  updateProfile(name: string, picture: string) {
    return this.afAuth.auth.currentUser.updateProfile({
      displayName: name,
      photoURL: picture
    });
  }

  getUsers() {
    this.usuarios = this.usuariosCollection.snapshotChanges().pipe(map(
      actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      }
    ));
    return this.usuarios;
  }

  getUser(id: string) {
    return this.usuariosCollection.doc<UserI>(id).valueChanges();
  }
  updateUser(id: string, user: UserI) {
    return this.usuariosCollection.doc(id).update(user);
  }
  addUser(user: UserI) {
    return this.usuariosCollection.add(user);
  }
  setUser(id: string, user: UserI) {
    return this.usuariosCollection.doc(id).set(user);
  }
  removeUser(id: string) {
    return this.usuariosCollection.doc(id).delete();
  }
}
