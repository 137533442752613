<div class="app-container">
    <div class="h-100 bg-premium-dark bg-animation">
        <div class="d-flex h-100 justify-content-center align-items-center">
            <div class="mx-auto app-login-box col-md-8">
                <div class="app-logo-inverse mx-auto mb-3"></div>
                <div class="modal-dialog w-100 mx-auto">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="h5 modal-title text-center">
                                <h4 class="mt-2">
                                    <div>Bienvenido de nuevo,</div>
                                    <span>Inicie sesión en su cuenta a continuación.</span>
                                </h4>
                            </div>
                            <form class="">
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <input name="email" id="exampleEmail" placeholder="Ingrese Email" [(ngModel)]="email" type="email" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <input name="password" id="examplePassword" placeholder="Ingrese Password" [(ngModel)]="password" type="password" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="divider"></div>
                            <h6 class="mb-0">¿No tienes cuenta?, <a [routerLink]="['/registro']" class="text-primary">Regístrate ahora</a></h6>
                        </div>
                        <div class="modal-footer clearfix">
                            <div class="float-left">
                                <a [routerLink]="['/recoverpass']" class="btn-lg btn btn-link">Recuperar contraseña</a>
                            </div>
                            <div class="float-right">
                                <button class="btn btn-primary btn-lg" (click)="onLogin()">
                                    Login to Dashboard
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>