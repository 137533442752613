import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/servicios/auth.service';
import * as firebase from 'firebase/app';
import '@firebase/messaging';

import { BehaviorSubject } from 'rxjs';
import { FcmtokensI } from '../interfaces/fcmtokens';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  messaging = firebase.messaging();
  currentMessage = new BehaviorSubject(null);

  constructor(
    private Auth: AuthService,
  ) { }

  private updateToken(token) {
    this.Auth.userData$.subscribe(user => {
      if (!user) return;

      this.Auth.updateUser(user.uid, {fcmtoken: token});
    });
  }

  getPermission() {
    this.messaging.requestPermission()
    .then(() => {
      console.log('Notificaction permission granted');
      return this.messaging.getToken();
    })
    .then(token => {
      console.log(token);
      this.updateToken(token);
    })
    .catch(err => {
      console.log('Error ', err);
    });
  }

  receiveMessage() {
    this.messaging.onMessage((payload) => {
      console.log("Message received.", payload);
      this.currentMessage.next(payload);
    });
  }

}
